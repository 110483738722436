import {useSelector} from "react-redux";
import HRStats from "../pages/HRStats";
import SupportStats from "../pages/SupportStats";
import {Typography} from "@mui/material";

const Stats = () => {
    const project = useSelector(state => state.session.project);
    if(project === 'hr') {
        return <HRStats/>
    }
    if(project === 'support'){
        return <SupportStats/>
    }
    return <div><Typography variant={'h1'} align={'center'}>Для выбранного проекта нет статистики</Typography> </div>
}
export default Stats;